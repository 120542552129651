var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scripts"},[_c('panes',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"script-list"},[_c('h1',[_vm._v(" Scripts "),_c('base-button',{staticClass:"right",attrs:{"type":"THEMED","iconType":"PLUS","title":"Create new Script"},on:{"click":_vm.createOne}})],1),_c('TableFilter',{attrs:{"source":_vm.scripts,"fields":[
            { key: 'description', label: 'Description', type: 'RegExp' },
            {
              key: 'os',
              label: 'OS',
              type: 'select',
              options: {
                '': 'All',
                linux: 'Linux',
                windows: 'Windows',
              },
            },
          ]},on:{"filtered":(filtered) => {
              _vm.scriptsDisplay = filtered;
            }}}),(_vm.ajaxCompleted)?_c('Table',{attrs:{"tabledata":_vm.scriptsDisplay},on:{"rowClick":_vm.handleRowClick,"reloadData":_vm.fetchAndFilter}}):_vm._e()],1)]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"script-view"},[(['scriptEdit'].indexOf(_vm.$route.name) > -1 && typeof _vm.selected === 'string')?_c('CreateEdit',{attrs:{"script-id":_vm.selected},on:{"saved":_vm.handleSaved,"deleted":_vm.handleDeleted}}):(_vm.create)?_c('CreateEdit',{attrs:{"script-id":-1},on:{"cancel":function($event){_vm.create = false},"saved":_vm.handleSaved,"deleted":_vm.handleDeleted}}):_vm._e()],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }