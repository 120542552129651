<template>
  <div v-if="ajaxCompleted">
    <h1 class="title" v-if="!create">Edit Script</h1>
    <h1 class="title" v-else>Create new Script</h1>
    <grid>
      <grid-col size="8">
        <card>
          <codemirror v-model="script.code" :options="cmOption" v-if="script" />
        </card>
      </grid-col>
      <grid-col size="4">
        <card title="Properties">
          <Property title="Description">
            <input
              type="text"
              placeholder="Description"
              class="input-text"
              v-model="script.description"
            />
          </Property>

          <Property title="Path" v-if="!create && script.path && !script.code">
            <input type="text" placeholder="Path" class="input-text" v-model="script.path" />
            <i
              class="uil uil-cloud-download"
              v-on:click="fetchRemoteCodeCdn"
              v-if="script.path"
            ></i>
          </Property>

          <Property title="Reboot">
            <label><CheckboxProperty v-model="script.reboot" /> Yes, reboot</label>
          </Property>

          <Property title="Reassignable">
            <label><CheckboxProperty v-model="script.reassignable" /> Yes, possible</label>
          </Property>

          <Property title="OS">
            <select name="os" class="input-select" v-model="script.os">
              <option value="">Select OS</option>
              <option v-for="os in ['linux', 'windows']" :key="os" :value="os">
                {{ os }}
              </option>
            </select>
          </Property>

          <Property title="Notes">
            <input type="text" placeholder="Notes" class="input-text" v-model="script.notes" />
          </Property>

          <Property title="Sort">
            <input type="number" placeholder="Sort" class="input-text" v-model="script.sort" />
          </Property>

          <Property title="Tags">
            <vue-tags-input
              class="tags-input"
              v-model="tag"
              :tags="tags"
              :autocomplete-min-length="0"
              :autocomplete-items="filteredTags"
              :add-only-from-autocomplete="true"
              @tags-changed="(newTags) => (script.tags = newTags)"
            />
          </Property>

          <Property>
            <div style="height: 40px">
              <base-button v-on:click="onSave" isSmall iconType="CHECK" title="Save" />
              <base-button v-on:click="onCancel" class="right" type="THEMED" title="Cancel" />
            </div>
          </Property>
        </card>
        <card v-if="!create">
          <base-button
            v-if="!create"
            type="DELETE"
            iconType="TRASH"
            v-on:click="handleOnDelete"
            title="Delete"
          />
          <base-button
            v-on:click="onCopyUrl"
            class="right"
            type="THEMED"
            iconType="COPY"
            title="Copy Signed Url"
          />
        </card>
      </grid-col>
    </grid>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import Utils from '@/utils';
import Property from '@/components/Util/Property.vue';
import { codemirror } from 'vue-codemirror';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseComponent from '../Base.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import CheckboxProperty from '../Property/CheckboxProperty.vue';

import 'codemirror/mode/powershell/powershell';
import 'codemirror/mode/shell/shell';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/mbo.css';

export default {
  name: 'CreateEditRestic',
  extends: BaseComponent,
  components: {
    BaseButton,
    Card,
    CheckboxProperty,
    codemirror,
    Grid,
    GridCol,
    Property,
    VueTagsInput,
  },

  props: ['scriptId'],
  watch: {
    scriptId(newVal) {
      this.getScript(newVal);
    },
  },
  data() {
    return {
      cmOption: {
        tabSize: 2,
        indentUnit: 2,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'application/x-powershell',
        theme: 'mbo',
      },
      code: '',
      ajaxCompleted: false,
      createHC: false,
      create: true,
      script: {
        code: '',
      },
      tag: '',
      tags: [],
      autocompleteItems: [],
    };
  },
  computed: {
    filteredTags() {
      return this.autocompleteItems.filter(
        (i) => i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1,
      );
    },
  },
  methods: {
    async getTags() {
      const response = await this.$utils.http().get('/api/v1/roletags');
      this.autocompleteItems = response.data.sort().map((e) => ({ text: e, classes: 'role' }));
    },
    copyToClipboard(text) {
      const dummy = document.createElement('textarea');
      // dummy.style.visibility = 'hidden';
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    },
    onCancel() {
      this.$router.push('/script');
      this.$emit('cancel');
      this.$root.$emit('reloadTable');
      this.selected = null;
      this.create = false;
    },
    async onCopyUrl() {
      const response = await Utils.fetch(
        `/api/v1/scripts/signed/${this.script._id}`,
        {},
        this,
      ).then((res) => res.json());

      const url = `${window.location.origin}${response.location}`;
      this.copyToClipboard(url);
      this.$noty.success('Copied URL to clipboard');
    },
    async onSave() {
      if (typeof this.script && this.script._id) {
        await this.handleOnUpdate();
      } else {
        await this.hasndleOnCreate();
      }
    },
    async handleOnUpdate() {
      // eslint-disable-next-line prefer-destructuring
      const script = this.script;
      script.tags = this.script.tags.map((t) => t.text ?? t);
      const response = await Utils.fetch(
        `/api/v1/scripts/${this.script._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            script,
          }),
        },
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.$noty.success('Saved script');
        this.$emit('saved');
      } else {
        this.$noty.warning(response.message);
      }
    },
    async hasndleOnCreate() {
      // eslint-disable-next-line prefer-destructuring
      const script = this.script;
      script.tags = this.script.tags.map((t) => t.text ?? t);

      const response = await Utils.post(
        '/api/v1/scripts',
        {
          organization: null, // this.script.organization,
          ...script,
        },
        this,
      );

      if (response.success) {
        // this.scriptId = response.script._id;
        this.$router.push(`/script/${response.script._id}/edit`);
        this.$emit('saved');
        this.$noty.success('Saved script');
      } else {
        this.$noty.warning(response.message);
      }
    },
    async handleOnDelete() {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Do you really want to delete this script?')) {
        const response = await Utils.delete(`/api/v1/scripts/${this.script._id}`, {}, this);
        if (response.success) {
          this.$noty.success('Deleted script');
          this.$nextTick(() => {
            this.onCancel();
          });
        } else {
          this.$noty.warning('Script was NOT deleted');
        }
      }
    },
    async getScript(id) {
      if (id === -1) {
        const response = await Utils.fetch('/api/v1/scripts/helper/empty', {}, this).then((res) =>
          res.json(),
        );
        if (response.success) {
          if (!response.script.code) {
            response.script.code = '';
          }

          this.ajaxCompleted = true;
          this.script = response.script;
          this.create = true;
          this.cmOption.mode = this.script.contentType;
        }
      } else {
        const response = await Utils.fetch(`/api/v1/scripts/${id}`, {}, this).then((res) =>
          res.json(),
        );
        if (response.success) {
          if (!response.script.code) {
            response.script.code = '';
          }

          this.ajaxCompleted = true;
          this.script = response.script;
          this.create = false;
          this.cmOption.mode = this.script.contentType;
        }
      }
      this.tags = this.script.tags.sort().map((e) => ({ text: e, classes: 'role' }));
    },
    // fetch a script from remote cdn
    // we use this during transition
    async fetchRemoteCodeCdn() {
      const resp = await Utils.fetchPlain(
        `https://cdn.wherever.network${this.script.path}`,
        {},
        this,
      );
      this.$set(this.script, 'code', await resp.text());
    },
  },
  mounted() {
    this.getScript(this.scriptId).then(() => {
      this.getTags();
    });
  },
};
</script>

<style scoped>
.property label {
  display: flex;
}
.property .checkbox {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
</style>
<style>
.CodeMirror {
  height: 640px;
}
</style>
