<template>
  <div class="scripts">
    <panes>
      <template v-slot:left>
        <div class="script-list">
          <h1>
            Scripts
            <base-button
              class="right"
              type="THEMED"
              iconType="PLUS"
              title="Create new Script"
              v-on:click="createOne"
            />
          </h1>
          <TableFilter
            :source="scripts"
            :fields="[
              { key: 'description', label: 'Description', type: 'RegExp' },
              {
                key: 'os',
                label: 'OS',
                type: 'select',
                options: {
                  '': 'All',
                  linux: 'Linux',
                  windows: 'Windows',
                },
              },
            ]"
            @filtered="
              (filtered) => {
                scriptsDisplay = filtered;
              }
            "
          />
          <!-- <div class="right">
            OS:
            <select name="filter-os" class="input-select" style="width: auto" v-model="filter.os">
              <option v-for="os in ['all', 'linux', 'windows']" :key="os" :value="os">
                {{ os }}
              </option>
            </select>
          </div> -->
          <Table
            v-if="ajaxCompleted"
            v-on:rowClick="handleRowClick"
            v-on:reloadData="fetchAndFilter"
            :tabledata="scriptsDisplay"
          />
          <!-- <div class="right">
            <p>Showing {{ scriptsDisplay.length }} / {{ scripts.length }} scripts</p>
          </div> -->
        </div>
      </template>
      <template v-slot:right>
        <div class="script-view">
          <CreateEdit
            v-if="['scriptEdit'].indexOf($route.name) > -1 && typeof selected === 'string'"
            :script-id="selected"
            v-on:saved="handleSaved"
            v-on:deleted="handleDeleted"
          />
          <CreateEdit
            v-else-if="create"
            v-on:cancel="create = false"
            :script-id="-1"
            v-on:saved="handleSaved"
            v-on:deleted="handleDeleted"
          />
        </div>
      </template>
    </panes>
  </div>
</template>
<script>
import Utils from '@/utils';
import Panes from '../components/Panes/Panes.vue';
import Table from '../components/Script/Table.vue';
import CreateEdit from '../components/Script/CreateEdit.vue';
import BaseButton from '../components/BaseButton/BaseButton.vue';
import TableFilter from '../components/Table/Filter.vue';

export default {
  name: 'Alert',
  components: {
    Panes,
    Table,
    CreateEdit,
    BaseButton,
    TableFilter,
  },
  data() {
    return {
      ajaxCompleted: false,
      selected: null,
      create: false,
      scripts: [],
      scriptsDisplay: [],
      filter: {
        os: 'all',
      },
    };
  },
  watch: {
    $route(val) {
      if (typeof val.params.script !== 'undefined') {
        this.selected = val.params.script;
      }
    },
    scripts(scripts) {
      this.scriptsDisplay = scripts;
    },
    filter: {
      handler() {
        this.filterScripts();
      },
      deep: true,
    },
  },
  methods: {
    handleRowClick(id) {
      this.create = false;
      this.snaps = false;
      this.selected = id;
      if (typeof id === 'string') {
        this.$router.replace(`/script/${id}/edit`);
      }
    },
    handleSaved() {
      this.$root.$emit('reloadTable');
    },
    handleDeleted() {
      this.handleSaved();
      this.selected = null;
    },
    createOne() {
      this.create = true;
      this.$router.replace('/script/create');
    },
    filterScripts() {
      localStorage.setItem('script-filter', JSON.stringify(this.filter));
      if (this.filter.os !== 'all') {
        this.scriptsDisplay = this.scripts.filter((script) => script.os === this.filter.os);
      } else {
        this.scriptsDisplay = this.scripts;
      }
    },
    async fetchAndFilter() {
      await this.getscripts();
      // .then(() => {
      //   this.filterScripts();
      // });
    },
    async getscripts() {
      this.ajaxCompleted = false;
      const response = await Utils.fetch('/api/v1/scripts', {}, this).then((res) => res.json());
      if (response.success) {
        this.ajaxCompleted = true;
        this.scripts = response.scripts.map((script) => {
          if (typeof script.sort === 'undefined') {
            // eslint-disable-next-line no-param-reassign
            script.sort = 0; // set a default sort for scripts that have not been modified
          }
          return {
            ...script,
            id: script._id,
          };
        });
      }
    },
  },
  async mounted() {
    const savedFilter = localStorage.getItem('script-filter');
    if (savedFilter) {
      this.filter = JSON.parse(savedFilter);
    }
    await this.fetchAndFilter();
    if (this.$route.meta.create) {
      this.create = true;
      this.selected = null;
    }
    if (typeof this.$route.params.script !== 'undefined') {
      this.selected = this.$route.params.script;
    }
  },
};
</script>

<style lang="scss" scoped>
.scripts {
  height: 100%;
}

.script-list {
  height: 100%;
  overflow-y: auto;
}

.script-view {
  height: 100%;
  overflow-x: auto;
}
</style>
